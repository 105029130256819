html {
	scroll-padding-top: 107px;
}
body {
	color: #000000;
	font-family: $font-NotoJP;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	overflow-x: hidden;
}

a {
	text-decoration: none;
	@include smooth-transition;

	&:hover {
		opacity: 0.7;
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: inline-block;
	}
	//&[href^="tel"] {
	//	@include mq(md-min){
	//		pointer-events: none;
	//	}
	//}
}

.clearfix {
	*zoom: 1;

	&:before {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

.main-page {
	margin-top: 109px;
	@include mq(xl) {
		margin-top: 108px;
	}
	@include mq(lg) {
		margin-top: 50px;
	}
}

.mv-page {
	&.cover {
		position: relative;
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			z-index: 1;
			opacity: 0.5;
			mix-blend-mode: multiply;
			.webp & {
				background: url("../../img/common/mv-bg.png.webp") no-repeat center center / cover;
			}
			.no-webp & {
				background: url("../../img/common/mv-bg.png") no-repeat center center / cover;
			}
		}
		.inner {
			z-index: 10;
		}
	}
	.mv-page__ttl .c-ft--img {
		margin-bottom: 25px;
		@media only screen and (max-width: 834px) {
			margin-bottom: 15px;
			max-width: 300px;
		}
		@media only screen and (max-width: 428px) {
			max-width: 250px;
		}
	}
}

.pankuzu {
	padding: 20px 15px 0;
	ul {
		max-width: 1400px;
		margin: 0 auto;
		li {
			font-size: 16px;
			color: #000;
			display: inline-block;
			margin-right: 14px;
			position: relative;
			&:after {
				content: "»";
				font-size: 16px;
				color: #000;
				position: absolute;
				right: -12px;
				top: 0;
			}
			&:last-child {
				margin-right: 0;
				&::after {
					display: none;
				}
			}
			a {
				font-size: 16px;
				color: #000;
			}
		}
	}
	@media only screen and (max-width: 834px) {
		padding: 15px 20px 0;
		ul {
			li {
				font-size: 12px;
				margin-right: 12px;
				&:after {
					font-size: 13px;
					right: -11px;
				}
				a {
					font-size: 12px;
				}
			}
		}
	}
}

.base-post {
	background: #e8e8e8;
	.inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	&.archive {
		padding: 70px 0 180px;
		.list {
			width: 73%;
			.box {
				background: #fff;
				padding: 30px 30px 30px 40px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin-bottom: 25px;
				&:last-child {
					margin-bottom: 0;
				}
				.img {
					width: 33%;
				}
				.block {
					width: 64%;
					.ttl {
						font-size: 23px;
						color: #000;
						font-weight: bold;
						letter-spacing: 0.1em;
						line-height: 1.6em;
						margin-bottom: 10px;
						font-feature-settings: "palt";
						a {
							color: #000;
						}
					}
					.txt {
						p {
							font-size: 14px;
							line-height: 1.8em;
							margin-bottom: 10px;
						}
					}
					.date {
						font-size: 13px;
						color: #555555;
					}
					.cat {
						a {
							font-size: 14px;
							color: #000;
							font-weight: bold;
							display: inline-block;
							margin-right: 7px;
						}
					}
				}
			}
			.pagenav {
				text-align: center;
				clear: both;
				margin-top: 50px;
				.current {
					font-size: 14px;
					color: #fff;
					border: 1px solid #000;
					padding: 5px 8px;
					background: #000;
				}
				a {
					font-size: 14px;
					color: #000;
					border: 1px solid #000;
					padding: 5px 8px;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
		@media only screen and (max-width: 834px) {
			padding: 35px 0 30px;
			.list {
				width: 100%;
				margin-bottom: 65px;
				.box {
					padding: 25px 22px 28px;
					.img {
						width: 100%;
						margin-bottom: 15px;
					}
					.block {
						width: 100%;
						.ttl {
							font-size: 16px;
							margin-bottom: 22px;
							.txt {
								p {
									font-size: 12px;
								}
							}
							.date {
								font-size: 12px;
							}
							.cat {
								a {
									font-size: 11px;
								}
							}
						}
					}
				}
				.pagenav {
					margin-top: 40px;
				}
			}
		}
	}
	&.detail {
		padding: 70px 0 110px;
		.post-block {
			width: 73%;
			.area-post {
				background: #fff;
				padding: 40px 60px 50px;
				margin-bottom: 40px;
				.ttlbox {
					margin-bottom: 40px;
					padding-bottom: 14px;
					border-bottom: 1px solid #d9d9d9;
					.ttl {
						font-size: 23px;
						color: #000;
						font-weight: bold;
						letter-spacing: 0.1em;
						line-height: 1.6em;
						margin-bottom: 15px;
						font-feature-settings: "palt";
					}
					.date {
						font-size: 14px;
						color: #555555;
						margin-bottom: 8px;
					}
					.cat {
						a {
							font-size: 14px;
							color: #000;
							font-weight: bold;
							display: inline-block;
							margin-right: 7px;
						}
					}
				}
				.thumb {
					margin-bottom: 40px;
				}
				.post {
					border-bottom: 1px solid #d9d9d9;
					margin-bottom: 45px;
					h2 {
						font-size: 16px;
						color: #fff;
						font-weight: bold;
						background: #000;
						padding: 10px 13px;
						margin-bottom: 20px;
					}
					h3 {
						font-size: 16px;
						font-weight: bold;
						letter-spacing: 0, 05em;
						background: #efefef;
						border-left: 5px solid #000;
						padding: 10px 15px;
						margin-bottom: 20px;
					}
					h4 {
						font-size: 16px;
						font-weight: bold;
						border-bottom: 1px dashed #ccc;
						padding-bottom: 5px;
						margin-bottom: 20px;
					}
					p {
						font-size: 14px;
						line-height: 1.8em;
						margin-bottom: 30px;
					}
					a {
						font-size: 14px;
						color: #337ab7;
						text-decoration: underline;
					}
					img {
						max-width: 100%;
						height: auto;
					}
					blockquote {
						font-size: 14px;
						background-color: #f7f7f7;
						padding: 30px 30px 20px 30px;
						border-radius: 10px;
						margin-bottom: 50px;
						position: relative;
						p {
							margin-bottom: 0;
						}
						&:before {
							content: "“";
							font-size: 300%;
							line-height: 1em;
							color: #000;
							position: absolute;
							left: 10px;
							top: 10px;
						}
						&::after {
							content: "”";
							font-size: 300%;
							line-height: 1em;
							color: #000;
							position: absolute;
							right: 10px;
							bottom: -10px;
						}
					}
					ul {
						font-size: 14px;
						padding-left: 15px;
					}
					ol {
						font-size: 14px;
						padding-left: 17px;
					}
					li {
						line-height: 1.8em;
						list-style: inherit;
					}
				}
				.box-staff {
					border: 1px solid #000;
					border-radius: 10px;
					margin-bottom: 35px;
					.label {
						font-size: 14px;
						color: #fff;
						font-weight: bold;
						background: #000;
						padding: 10px 13px;
						border-radius: 8px 8px 0 0;
					}
					.in {
						padding: 25px 30px;
						display: flex;
						justify-content: space-between;
						.block {
							width: 71%;
							ul {
								margin-bottom: 15px;
								li {
									font-size: 14px;
									margin-bottom: 8px;
									span {
										font-size: 18px;
									}
									&:last-child {
										margin-bottom: 0;
									}
								}
							}
							.txt {
								font-size: 14px;
								line-height: 1.8em;
							}
						}
						.img {
							width: 24%;
						}
					}
				}
				.box-contact {
					margin-bottom: 50px;
					.top {
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						margin-bottom: 14px;
						text-align: center;
						a {
							font-size: 18px;
							color: #fff;
							font-weight: bold;
							padding: 0 15px;
							line-height: 1.6em;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 49%;
							height: 80px;
							background: #000;
						}
					}
					.bottom {
						background: #000;
						padding: 25px 22px 25px 0;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						.label {
							width: 22%;
							text-align: center;
							padding: 0 10px;
							h3 {
								font-size: 18px;
								color: #fff;
								font-weight: bold;
								line-height: 1.6em;
							}
						}
						.qr {
							width: 17%;
						}
						.txt {
							width: 61%;
							padding-left: 25px;
							p {
								font-size: 12px;
								color: #fff;
								font-weight: bold;
								line-height: 1.8em;
							}
						}
					}
				}
				.sns {
					text-align: center;
					margin-bottom: 45px;
					li {
						display: inline-block;
						margin: 0 7px;
					}
				}
				.tag {
					display: flex;
					.label {
						font-size: 14px;
						color: #000;
						padding: 5px 10px 0 0;
					}
					ul {
						display: flex;
						flex-wrap: wrap;
						li {
							display: inline-block;
							margin: 0 10px 7px 0;
							a {
								font-size: 14px;
								color: #000;
								display: block;
								background: #e8e8e8;
								border-radius: 9999px;
								padding: 5px 10px;
							}
						}
					}
				}
			}
			.area-relation {
				.ttl {
					font-size: 17px;
					color: #fff;
					font-weight: bold;
					background: #000;
					padding: 10px 13px;
					letter-spacing: 0.05em;
				}
				ul {
					padding: 0 40px 40px;
					background: #fff;
					li {
						a {
							display: flex;
							align-items: center;
							justify-content: space-between;
							letter-spacing: 0.05em;
							padding: 30px 0;
							border-bottom: 1px solid #d9d9d9;
							.img {
								width: 18%;
							}
							.txt {
								width: 78%;
								font-size: 18px;
								color: #000;
								font-weight: bold;
								line-height: 1.6em;
							}
						}
					}
				}
			}
		}
		@media only screen and (max-width: 834px) {
			padding: 35px 0 30px;
			.post-block {
				width: 100%;
				margin-bottom: 40px;
				.area-post {
					padding: 20px 16px;
					margin-bottom: 30px;
					.ttlbox {
						margin-bottom: 30px;
						.ttl {
							font-size: 17px;
						}
						.date {
							font-size: 11px;
						}
						.cat {
							a {
								font-size: 11px;
							}
						}
					}
					.thumb {
						margin-bottom: 30px;
					}
					.post {
						margin-bottom: 30px;
					}
					.box-staff {
						.in {
							padding: 18px 20px 25px;
							.block {
								width: 100%;
								display: flex;
								flex-wrap: wrap;
								ul {
									margin-bottom: 0;
									width: 52%;
									padding-right: 15px;
									li {
										font-size: 13px;
										span {
											font-size: 13px;
										}
									}
								}
								.txt {
									font-size: 13px;
									margin-top: 20px;
								}
							}
							.img {
								width: 48%;
							}
						}
					}
					.box-contact {
						width: 92%;
						margin: 0 auto 25px;
						.top {
							margin-bottom: 0;
							a {
								font-size: 14px;
								width: 100%;
								height: 60px;
								margin-bottom: 10px;
							}
						}
						.bottom {
							padding: 20px;
							.label {
								width: 100%;
								padding: 0;
								margin-bottom: 10px;
								h3 {
									font-size: 14px;
								}
							}
							.qr {
								width: 100%;
								margin-bottom: 20px;
								a {
									display: block;
									font-size: 15px;
									color: #000;
									font-weight: bold;
									text-align: center;
									border-radius: 7px;
									max-width: 180px;
									background: #fff;
									margin: 0 auto;
									padding: 10px 0;
								}
							}
							.txt {
								width: 100%;
								padding-left: 0;
								p {
									font-size: 13px;
								}
							}
						}
					}
					.sns {
						margin-bottom: 27px;
						li {
							margin: 0 5px;
						}
					}
					.tag {
						.label {
							font-size: 11px;
							padding: 3px 0 0;
							width: 43px;
						}
						ul {
							li {
								margin-right: 5px;
								a {
									font-size: 11px;
									padding: 3px 7px;
								}
							}
						}
					}
				}
				.area-relation {
					.ttl {
						font-size: 14px;
					}
					ul {
						padding: 0 20px 40px;
						li {
							a {
								padding: 27px 0;
								align-items: flex-start;
								.img {
									width: 38%;
								}
								.txt {
									width: 53%;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
	.sidebar {
		width: 23%;
		.box {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				font-size: 16px;
				color: #000;
				font-weight: bold;
			}
			.ttl {
				font-size: 17px;
				color: #fff;
				font-weight: bold;
				background: #000;
				padding: 10px 13px;
				letter-spacing: 0.05em;
			}
			&.new {
				ul {
					padding: 0 18px 13px;
					background: #fff;
					li {
						a {
							display: block;
							letter-spacing: 0.05em;
							padding: 18px 0;
							border-bottom: 1px solid #d9d9d9;
							&:last-child {
								a {
									border-bottom: none;
								}
							}
						}
					}
				}
			}
			&.category {
				ul {
					padding: 0 18px 25px;
					background: #fff;
					li {
						a {
							display: block;
							letter-spacing: 0.05em;
							padding: 10px 0 10px 19px;
							border-bottom: 1px solid #d9d9d9;
							position: relative;
							&:before {
								content: "▶";
								position: absolute;
								left: 0;
								top: 10px;
							}
						}
						ul {
							padding: 0;
							li {
								a {
									font-size: 14px;
									font-weight: normal;
									padding: 10px 0 10px 43px;
									&:before {
										left: 25px;
									}
								}
							}
						}
					}
				}
			}
			&.list-item {
				ul {
					padding: 5px 18px 25px;
					background: #fff;
					li {
						a {
							font-size: 14px;
							font-weight: normal;
							display: block;
							letter-spacing: 0.05em;
							padding: 10px 0;
							border-bottom: 1px solid #d9d9d9;
						}
					}
				}
			}
		}
		@media only screen and (max-width: 834px) {
			width: 100%;
		}
	}
}

.page-404 {
	text-align: center;
	.inner {
		padding: 30px 0 80px;
		@media only screen and (max-width: 834px) {
			padding: 60px 0 70px;
		}
	}
	.ttl {
		font-size: 130px;
		color: #000;
		font-weight: bold;
		line-height: 1em;
		margin-bottom: 35px;
		@media only screen and (max-width: 834px) {
			font-size: 110px;
		}
		small {
			font-size: 24px;
			display: block;
			line-height: 1em;
			letter-spacing: 0.05em;
			@media only screen and (max-width: 834px) {
				font-size: 20px;
			}
		}
	}
	.txt {
		font-size: 14px;
		line-height: 1.6em;
	}
}

.no-txt {
	@include font-size(16);
	&.center {
		text-align: center;
		width: 100%;
	}
	@media only screen and (max-width: 834px) {
		@include font-size(14);
	}
}

.sec-works {
	padding: 115px 0 120px;
	background: #000;
	@include mq(xl) {
		padding: 80px 0;
	}
	@media only screen and (max-width: 834px) {
		padding: 39px 0 52px;
	}
}

.works {
	&-list {
		@include flex();
		margin: 0 -19px 99px;
		@include mq(xl) {
			margin: 0 -10px 50px;
		}
		@media only screen and (max-width: 834px) {
			margin: 0 0 43px;
			padding: 0 2px;
			@include flex(0, 0, wrap);
		}
		.item {
			width: 25%;
			padding: 0 19px;
			@include mq(xl) {
				padding: 0 10px;
			}
			@media only screen and (max-width: 834px) {
				width: 100%;
				padding: 0;
				margin-bottom: 21px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			a {
				display: block;
				@media only screen and (max-width: 834px) {
					@include flex();
				}
			}
		}
		&__img {
			position: relative;
			overflow: hidden;
			margin-bottom: 22px;
			@media only screen and (max-width: 834px) {
				margin-bottom: 0;
				width: 41%;
			}
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(196 / 270 * 100%);
			}
			&-thumb {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
		}
		&__ct {
			@media only screen and (max-width: 834px) {
				width: 59%;
				padding-left: 16px;
				margin-top: -5px;
			}
			&-date {
				display: block;
				color: #fff;
				margin-bottom: 5px;
				@include font-size(18);
				@include mq(xl) {
					@include font-size(16);
				}
				@media only screen and (max-width: 834px) {
					@include font-size(14);
				}
			}
			&-ttl {
				line-height: 1.6;
				color: #fff;
				word-break: break-all;
				@include font-size(18);
				@include mq(xl) {
					@include font-size(16);
				}
				@include mq(lg) {
					@include font-size(15);
				}
				@media only screen and (max-width: 834px) {
					@include font-size(14);
				}
			}
		}
	}
}

.sec-service {
	padding: 106px 0 0;
	@include mq(xl) {
		padding: 80px 0 0;
	}
	@media only screen and (max-width: 834px) {
		padding: 55px 0 0;
		.c-ttl__01 {
			margin-bottom: 33px;
			.en {
				margin-bottom: 0;
			}
		}
	}
	.c-txt__basic02 {
		text-align: center;
		margin-bottom: 36px;
		@media only screen and (max-width: 834px) {
			margin-bottom: 31px;
		}
	}
}

.service {
	&-bg {
		padding: 117px 0 80px;
		background: #000;
		@include mq(xl) {
			padding: 80px 0;
		}
		@media only screen and (max-width: 834px) {
			padding: 29px 0 25px;
			.inner {
				padding: 0 24px;
			}
		}
	}
	&-list {
		margin-bottom: 99px;
		@include mq(xl) {
			margin-bottom: 50px;
		}
		@media only screen and (max-width: 834px) {
			margin-bottom: 25px;
		}
		.item {
			margin-bottom: 90px;
			@include mq(xl) {
				margin-bottom: 50px;
			}
			@media only screen and (max-width: 834px) {
				margin-bottom: 17px;
				&:last-child {
					margin-bottom: 0;
				}
				.c-btn__02 {
					display: none;
				}
			}
			a {
				@include flex(0, center);
				@media only screen and (max-width: 834px) {
					@include flex(0, 0, wrap);
				}
			}
			&:nth-child(2n) {
				.service-list__img {
					order: 2;
					@media only screen and (max-width: 834px) {
						order: 1;
					}
				}
				.service-list__ct {
					order: 1;
					padding-left: 80px;
					padding-right: 135px;
					@include mq(xl) {
						padding: 20px;
					}
					@media only screen and (max-width: 834px) {
						padding: 13px 10px;
						order: 2;
					}
				}
			}
		}
		&__img {
			width: 47.6%;
			position: relative;
			overflow: hidden;
			@media only screen and (max-width: 834px) {
				width: 100%;
			}
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(751 / 1148 * 100%);
			}
			&-thumb {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
		}
		&__ct {
			width: 52.4%;
			padding-left: 132px;
			padding-right: 80px;
			padding-bottom: 10px;
			@include mq(xl) {
				padding: 20px;
			}
			@media only screen and (max-width: 834px) {
				width: 100%;
				padding: 13px 10px;
			}
			&-ttl {
				color: #fff;
				line-height: 1.2;
				font-weight: 500;
				margin-bottom: 36px;
				@include mq(xl) {
					margin-bottom: 25px;
				}
				@media only screen and (max-width: 834px) {
					margin-bottom: 17px;
					position: relative;
					&:after {
						content: "";
						width: 10px;
						height: 10px;
						border-top: 2px solid #fff;
						border-right: 2px solid #fff;
						position: absolute;
						right: 4px;
						top: 9px;
						transform: rotate(45deg);
					}
				}
				.en {
					font-family: $font-Jost;
					display: block;
					letter-spacing: 4.5px;
					margin-bottom: 9px;
					@include font-size(44);
					line-height: 1em;
					@include mq(xl) {
						letter-spacing: 2px;
						margin-bottom: 5px;
					}
					@media only screen and (max-width: 834px) {
						margin-bottom: 2px;
						@include font-size(27);
						line-height: 1.2em;
					}
				}
				.jp {
					display: block;
					@include font-size(20);
					@include mq(xl) {
						@include font-size(18);
					}
					@media only screen and (max-width: 834px) {
						@include font-size(14);
					}
				}
			}
			.c-txt__basic {
				color: #fff;
				margin-bottom: 30px;
				@include mq(xl) {
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 834px) {
					margin-bottom: 0;
					font-feature-settings: "palt" 1;
					@include font-size(14);
				}
			}
		}
		&--02 {
			@include flex();
			margin: 0 -22px;
			@include mq(xl) {
				margin: 0 -10px;
			}
			@media only screen and (max-width: 834px) {
				@include flex(0, 0, wrap);
				margin: 0;
			}
			.item {
				margin-bottom: 0;
				width: 50%;
				padding: 0 22px;
				@include mq(xl) {
					padding: 0 10px;
				}
				@media only screen and (max-width: 834px) {
					width: 100%;
					margin-bottom: 17px;
					padding: 0;
				}
				a {
					display: block;
				}
				@include mq(md-min) {
					.service-list__ct {
						width: 100%;
						padding: 0 75px 0 80px !important;
						@include mq(xl) {
							padding: 0 20px !important;
						}
					}
					.service-list__img {
						width: 100%;
						margin-bottom: 30px;
					}
					&:nth-child(2n) {
						.service-list__img {
							order: 1;
						}
						.service-list__ct {
							order: 2;
						}
					}
				}
			}
		}
	}
}

.step {
	&-list {
		@include flex();
		flex-wrap: wrap;
		margin: 0 -22px 101px;
		@include mq(mac) {
			margin: 0 -10px;
		}
		@media only screen and (max-width: 834px) {
			@include flex(0, 0, wrap);
			margin: 0 0 50px !important;
		}
		.item {
			padding: 0 22px;
			width: 33.33%;
			margin-bottom: 40px;
			@include mq(mac) {
				padding: 0 10px;
			}
			@media only screen and (max-width: 834px) {
				width: 100%;
				padding: 0 !important;
				margin-bottom: 35px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			a {
				display: block;
			}
			.c-txt__basic {
				color: #fff;
				margin-bottom: 30px;
				@include mq(xl) {
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 834px) {
					margin: 0 10px;
					font-feature-settings: "palt" 1;
					@include font-size(14);
				}
			}
			.c-btn__02 {
				@media only screen and (max-width: 834px) {
					display: none;
				}
			}
		}
		&__img {
			position: relative;
			overflow: hidden;
			margin-bottom: 13px;
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(484 / 741 * 100%);
			}
			&-thumb {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
		}
		&__ttl {
			color: #fff;
			font-weight: 500;
			line-height: 1.2;
			margin-bottom: 36px;
			@include mq(xl) {
				margin-bottom: 20px;
			}
			@media only screen and (max-width: 834px) {
				margin: 0 10px 15px;
				position: relative;
				&:after {
					content: "";
					width: 10px;
					height: 10px;
					border-top: 2px solid #fff;
					border-right: 2px solid #fff;
					position: absolute;
					right: 4px;
					top: 12px;
					transform: rotate(45deg);
				}
			}
			.en {
				display: block;
				font-family: $font-Jost;
				margin-bottom: 0;
				letter-spacing: 1px;
				@include font-size(40);
				@include mq(xl) {
					@include font-size(28);
				}
			}
			.jp {
				display: block;
				@include font-size(20);
				@include mq(xl) {
					@include font-size(18);
				}
				@media only screen and (max-width: 834px) {
					@include font-size(14);
				}
			}
		}
	}
}