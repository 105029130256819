#footer {
	background: #fff;
	padding: 0;
}

.ft {
	&-top {
		margin-bottom: 100px;
		@include flex();
		@media only screen and (max-width: 834px) {
			margin-bottom: 30px;
			@include flex(0, 0, wrap);
		}
		.item {
			width: 55.55%;
			padding-right: 65px;
			&:nth-child(2) {
				width: 44.45%;
				padding-right: 0;
			}
			@media only screen and (max-width: 834px) {
				margin-bottom: 47px;
				width: 100% !important;
				padding-right: 0 !important;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&__ttl {
			color: #000;
			font-weight: 500;
			margin-bottom: 16px;
			letter-spacing: -1px;
			@include font-size(20);
			@media only screen and (max-width: 834px) {
				padding-left: 5px;
				letter-spacing: 0.5px;
				margin-bottom: 10px;
				@include font-size(15);
			}
			a {
				color: #000;
			}
		}
		&__bread {
			width: 100%;
			margin-bottom: 27px;
			@media only screen and (max-width: 834px) {
				padding-left: 3px;
				margin-bottom: 20px;
			}
			td {
				position: relative;
				overflow: hidden;
				border: 1px solid #e0e0e0;
				&:before {
					content: "";
					display: block;
					padding-bottom: calc(1 / 1 * 100%);
				}
				a,
				span {
					position: absolute;
					@include flex(center, center);
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}
				picture {
					display: contents;
				}
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
		&__maker {
			.box {
				margin-bottom: 27px;
				@media only screen and (max-width: 834px) {
					margin-bottom: 20px;
				}
				&:last-child {
					margin-bottom: 0;
				}
				ul {
					border-top: 1px solid #e0e0e0;
					border-left: 1px solid #e0e0e0;
					display: flex;
					flex-wrap: wrap;
					li {
						width: 16.666%;
						border-right: 1px solid #e0e0e0;
						border-bottom: 1px solid #e0e0e0;
					}
				}
			}
		}
		&__nav {
			@include flex();
			@media only screen and (max-width: 834px) {
				padding-left: 6px;
				margin-right: -3px;
			}
			ul {
				width: 21.25%;
				@media only screen and (max-width: 834px) {
					width: 25%;
				}
				li {
					margin-bottom: 14.5px;
					@media only screen and (max-width: 834px) {
						margin-bottom: 5.5px;
					}
					a,
					span {
						color: #000;
						@include font-size(14);
						@media only screen and (max-width: 834px) {
							@include font-size(12);
						}
					}
				}
			}
		}
	}
	&-main {
		background: #000;
		padding: 64px 0 100px;
		@media only screen and (max-width: 834px) {
			padding: 40px 0 95px;
		}
		li {
			a {
				margin-bottom: 22px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.inner {
			@include flex();
			@include mq(lg) {
				@include flex(0, 0, wrap);
			}
			@media only screen and (max-width: 834px) {
				padding: 0 26px;
			}
		}
	}
	&-ct {
		width: 400px;
		@include mq(xl) {
			width: 320px;
		}
		@include mq(lg) {
			width: 100%;
			margin-bottom: 35px;
			text-align: center;
		}
		@media only screen and (max-width: 834px) {
			text-align: left;
			max-width: 245px;
			margin: 0 auto 34px;
		}
		&__logo {
			margin-bottom: 15px;
			@media only screen and (max-width: 834px) {
				margin-bottom: 12px;
				text-align: center;
			}
			a {
				display: inline-block;
			}
			img {
				@media only screen and (max-width: 834px) {
					width: 180px;
				}
			}
		}
		&__address {
			color: #fff;
			line-height: 1.2;
			margin-bottom: 5px;
			@include font-size(12);
			@media only screen and (max-width: 834px) {
				padding-left: 13px;
				@include font-size(14);
				line-height: 1.5em;
			}
		}
		&__tel {
			margin-bottom: 14px;
			@media only screen and (max-width: 834px) {
				margin-bottom: 12px;
			}
			a {
				display: inline-block;
				color: #fff;
				font-weight: bold;
				@include font-size(25);
				@media only screen and (max-width: 834px) {
					padding-left: 13px;
					@include font-size(19);
				}
			}
		}
		&__share {
			@include flex(0, center);
			@include mq(lg) {
				justify-content: center;
			}
			@media only screen and (max-width: 834px) {
				padding-left: 15px;
				justify-content: flex-start;
			}
			li {
				margin-right: 17px;
				@media only screen and (max-width: 834px) {
					margin-right: 13px;
				}
				a {
					display: block;
				}
			}
		}
	}
	&-nav {
		width: calc(100% - 400px);
		@include mq(xl) {
			width: calc(100% - 320px);
		}
		@include mq(lg) {
			width: 100%;
		}
		&__main {
			@include flex(between, 0, wrap);
			padding-top: 10px;
			margin-right: -85px;
			@include mq(mac) {
				margin-right: 0;
			}
			@media only screen and (max-width: 834px) {
				padding-top: 0;
				border-top: 1px solid #fff;
			}
			> li {
				padding-right: 10px;
				min-width: 80px;
				@media only screen and (max-width: 834px) {
					padding: 11px 0 8px;
					width: 100%;
					min-width: max-content;
					border-bottom: 1px solid #fff;
				}
				> a {
					color: #fff;
					padding-left: 7px;
					display: block;
					border-left: 5px solid #fff;
					@include font-size(14);
					@include mq(xl) {
						padding-left: 4px;
						border-width: 2px;
						@include font-size(14);
					}
					@media only screen and (max-width: 834px) {
						padding-left: 8px;
						border-width: 3px;
						line-height: 1.2;
						margin: 0;
					}
				}
				ul {
					margin-top: 22px;
					@media only screen and (max-width: 834px) {
						border-top: 1px solid #fff;
						margin-top: 10px;
						margin-bottom: -8px;
					}
					li {
						margin-bottom: 23px;
						@media only screen and (max-width: 834px) {
							border-bottom: 1px solid #fff;
							padding: 8px 0 9px;
							margin-bottom: 0;
							&:last-child {
								border-bottom: 0;
							}
						}
						a {
							position: relative;
							display: block;
							color: #fff;
							@include font-size(13);
							padding-left: 15px;
							@include mq(xl) {
								@include font-size(14);
							}
							&:before {
								content: "▶";
								color: #e8e8e8;
								margin-right: 2px;
								@include font-size(10);
								position: absolute;
								left: 0;
								top: 3px;
							}
							@media only screen and (max-width: 834px) {
								padding-left: 17px;
							}
						}
					}
				}
			}
			&--02 {
				margin-top: -232px;
				//@include mq(mac){
				//	margin-top: -180px;
				//}
				@include mq(xl) {
					margin-top: -238px;
				}
				@media only screen and (max-width: 834px) {
					margin-top: 0;
					border-top: 0;
				}
				li {
					width: 100%;
					margin: 0 0 25px;
					@media only screen and (max-width: 834px) {
						padding: 12px 0 8px;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.sec-inquiries {
	background: #000;
	padding: 65px 0 95px;
	margin-bottom: 94px;
	@media only screen and (max-width: 834px) {
		padding: 38px 0 48px;
		margin-bottom: 70px;
		.inner {
			padding: 0 30px;
		}
	}
}

.inquiries {
	&-ttl {
		text-align: center;
		color: white;
		font-weight: bold;
		@include font-size(34);
		margin-bottom: 89px;
		letter-spacing: 1px;
		@media only screen and (max-width: 834px) {
			@include font-size(20);
			margin-bottom: 32px;
		}
	}

	&-list {
		@include flex(0, 0, wrap);
		margin: 0 -20px;

		> .item {
			width: 50%;
			padding: 0 20px;
			margin-bottom: 30px;
			@media only screen and (max-width: 834px) {
				width: 100%;
				margin-bottom: 16px;
			}

			&-full {
				width: 100%;
				padding: 0 20px;

				.inquiries-list__wrap {
					&-ttl {
						width: 340px;
						text-align: center;
						@media only screen and (max-width: 834px) {
							width: 100%;
							margin: 13px 0 17px;
						}
					}

					&-link {
						width: 190px;
						@media only screen and (max-width: 834px) {
							width: 100%;
							max-width: 180px;
							margin: 0 auto 25px;
							text-align: center;
						}
					}

					&-txt {
						width: calc(100% - 530px);
						padding-left: 35px;
						@media only screen and (max-width: 834px) {
							width: 100%;
							padding-left: 0;
						}
					}
				}
			}
		}

		&__wrap {
			@include flex(center, center, wrap);
			height: 100%;
			background: white;
			padding: 10px 25px 10px 10px;
			@media only screen and (max-width: 834px) {
				padding: 15px 17px;
			}

			&-ttl {
				color: #000;
				font-weight: 500;
				@include font-size(24);
				@media only screen and (max-width: 834px) {
					@include font-size(18);
				}
				@include mq(xs) {
					@include font-size(16);
				}

				span {
					display: block;
					font-weight: bold;
					@include font-size(35);
					margin-top: 8px;
					@media only screen and (max-width: 834px) {
						@include font-size(26.25);
						margin-top: 6px;
					}
					@include mq(xs) {
						@include font-size(24);
					}
				}

				&--ar {
					position: relative;
					padding: 0 65px 6px 0;
					letter-spacing: 0.5px;
					@include mq(xl) {
						padding: 19px 47px 24px 0;
					}

					&:after {
						content: "";
						position: absolute;
						width: 118px;
						height: 9px;
						border: solid #000;
						border-width: 0 2px 2px 0;
						border-left: 2px solid transparent;
						transform: skew(45deg);
						bottom: 17px;
						right: -29%;
						@include mq(xl) {
							width: 24px;
							height: 6px;
							bottom: 32px;
							right: -7px;
						}
					}
				}
			}

			&-link {
				padding-left: 13px;
				@media only screen and (max-width: 834px) {
					background: #000;
					border-radius: 5px;
					color: white;
					font-weight: 500;
					@include font-size(15);
					padding: 5px;
				}
			}

			&-txt {
				@include fts(14, 28);
				padding-bottom: 5px;
				@media only screen and (max-width: 834px) {
					@include fts(14, 21);
				}
			}
		}
	}
}
