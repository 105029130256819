#header {
	.hd-flex {
		background: #000;
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 99;
		padding: 0 162px 0 313px;
		@include flex(0, center, wrap);
		@include mq(mac) {
			padding: 0 20px;
		}
		@include mq(lg) {
			padding: 3px 12px !important;
		}
		&__logo {
			width: 210px;
			@include mq(lg) {
				width: 120px;
			}
			img {
				width: 100%;
			}
		}
		&__content {
			width: calc(100% - 210px);
			@include mq(lg) {
				width: 100%;
				height: 100vh;
				background: #000;
				padding: 48px 26px 100px;
				overflow-y: auto;
				position: fixed;
				left: 0;
				top: 0;
				pointer-events: none;
				z-index: -1;
				opacity: 0;
				transition: all 0.3s;
				&.on {
					z-index: 99;
					pointer-events: auto;
					opacity: 1;
					transition: all 0.3s;
				}
			}
		}
		&__inner {
			@include mq(lg) {
				@include flex(0, end, reverse, end);
			}
		}
		&__top {
			@include flex(end, center);
			margin-bottom: 10px;
			@include mq(lg) {
				width: 100%;
				@include flex(start, center);
			}
			&-nav {
				@include flex(0, center);
				li {
					margin-left: 28px;
					padding-top: 15px;
					@include mq(xl) {
						margin-left: 20px;
					}
					a {
						display: block;
						color: #fff;
						font-weight: 500;
						@include font-size(14);
					}
				}
			}
			&-share {
				@include flex(0, center);
				padding-top: 14px;
				margin-left: 3px;
				@include mq(xl) {
					padding-top: 10px;
				}
				@include mq(lg) {
					padding-left: 3px;
				}
				li {
					margin-left: 21px;
					@include mq(xl) {
						margin-left: 15px;
					}
					@include mq(lg) {
						margin-right: 12.5px;
						margin-left: 0;
					}
					a {
						display: block;
					}
					&.mail {
						display: none;
					}
				}
			}
			&-tel {
				margin-left: 29px;
				padding-top: 8px;
				@include mq(xl) {
					margin-left: 20px;
				}
				a {
					display: block;
					font-weight: bold;
					color: #fff;
					@include font-size(25);
					@include mq(xl) {
						@include font-size(20);
					}
				}
			}
			&-mail {
				margin-left: 15px;
				a {
					width: 55px;
					height: 48px;
					padding: 12px;
					background: #fff;
					display: block;
				}
			}
		}
		.nav-menu {
			@include mq(lg) {
				width: 100%;
				margin-bottom: 5px;
			}
			.show-menu {
				@include flex(end, 0, wrap);
				@include mq(lg) {
					border-top: 1px solid #fff;
				}
				> .item {
					position: relative;
					margin-left: 52px;
					@include mq(mac) {
						margin-left: 30px;
					}
					@include mq(xl) {
						margin-left: 25px;
					}
					@include mq(lg) {
						margin-left: 0 !important;
						width: 100%;
						border-bottom: 1px solid #fff;
					}
					&:first-child {
						margin-left: 0;
					}
					> a {
						padding: 15px 0;
						display: block;
						font-weight: bold;
						color: #fff;
						position: relative;
						@include font-size(17);
						@media only screen and (max-width: 834px) {
							padding: 10px 17px;
							&:before {
								content: "";
								width: 3px;
								height: 16px;
								background: #fff;
								top: 15px;
								left: 7px;
								position: absolute;
							}
						}
						@include mq(lg-min) {
							&:before {
								content: "";
								width: 100%;
								height: 3px;
								background: #fff;
								bottom: 9px;
								left: 0;
								position: absolute;
								opacity: 0;
								transition: all 0.3s;
							}
						}
					}
					&.active {
						a {
							&:before {
								opacity: 1;
							}
						}
					}
					@include mq(md-min) {
						&:hover {
							a {
								&:before {
									opacity: 1;
									transition: all 0.3s;
								}
							}
							.mega-menu {
								opacity: 1;
								transition: all 0.3s;
								pointer-events: auto;
								z-index: 99;
							}
						}
					}
					.mega-menu {
						position: fixed;
						width: 100%;
						top: 109px;
						background: rgba(0,0,0,0.7);
						left: 0;
						padding: 25px 20px 19px;
						opacity: 0;
						z-index: -1;
						pointer-events: none;
						@include mq(lg-min) {
							transition: all 0.3s;
						}
						@include mq(xl) {
							padding: 30px 20px 20px;
							top: 107px;
						}
						@include mq(lg) {
							position: static;
							opacity: 1;
							z-index: 99;
							pointer-events: auto;
							background: none;
							padding: 0;
							display: none;
						}
						.block-menu {
							display: flex;
							justify-content: flex-end;
							max-width: 1200px;
							margin: 0 auto;
							.box {
								width: 32%;
								margin: 0 2% 20px 0;
								border: 2px solid #c10000;
								background: #fff;
								display: flex;
								&:nth-child(3n) {
									margin-right: 0;
								}
								.img {
									width: 41%;
									position: relative;
									padding-top: 32%;
									img {
										object-fit: cover;
										position: absolute;
										left: 0;
										top: 0;
										width: 100%;
										height: 100%;
									}
								}
								.txt {
									width: 59%;
									padding: 15px;
									height: 100%;
									.txt01 {
										@include font-size(16);
										color: #000;
										font-weight: bold;
										margin-bottom: 10px;
									}
									.txt02 {
										@include font-size(12);
										color: #000;
									}
								}
							}
						}
						ul {
							@include flex(center);
							@include mq(lg) {
								@include flex(0, 0, wrap);
								border-top: 1px solid #fff;
							}
							li {
								padding: 0 13px;
								@include mq(lg) {
									border-bottom: 1px solid #fff;
									width: 100%;
									padding: 0;
									&:last-child {
										border-bottom: 0;
									}
								}
								a {
									display: block;
									color: #fff;
									font-weight: 500;
									position: relative;
									@include font-size(14);
									padding-left: 15px;
									@include mq(lg) {
										color: #fff;
										padding: 10px 17px;
									}
									@include mq(xl) {
										@include font-size(15);
									}
									&:before {
										content: "▶";
										color: #616161;
										margin-right: 4px;
										position: absolute;
										left: 0;
										top: 3px;
										@include font-size(12);
										@include mq(lg) {
											margin-right: 3px;
											top: 12px;
										}
									}
								}
							}
						}
					}
				}
				.accordion {
					display: none;
					@include mq(lg) {
						display: block;
						position: absolute;
						right: 0;
						top: 0;
						width: 38px;
						height: 34px;
						&:before {
							content: "";
							width: 7px;
							height: 7px;
							border-bottom: 1px solid #fff;
							border-right: 1px solid #fff;
							position: absolute;
							top: 17px;
							left: 12px;
							transform: rotate(45deg);
							transition: all 0.3s;
						}
						&.on {
							&:before {
								top: 21px;
								left: 13px;
								transform: rotate(-135deg);
								transition: all 0.3s;
							}
						}
					}
				}
			}
		}
	}

	.mobile-icon {
		width: 25px;
		height: 30px;
		position: fixed;
		right: 13px;
		top: 11px;
		cursor: pointer;
		z-index: 99;
		display: none;
		@include mq(lg) {
			@include flex(center, center);
		}
		.off {
			display: none;
		}
		&.mobile-close {
			top: 9px;
			.on {
				display: none;
			}
			.off {
				display: block;
			}
		}
	}
}

.is-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	body {
		-webkit-overflow-scrolling: auto;
	}
}

.menu-fixed {
	position: fixed;
	bottom: 0;
	z-index: 98;
	width: 100%;
	left: 0;
	background: #000;
	@include flex(0, center);
	li {
		width: 20%;
		border-right: 1px solid #fff;
		&:last-child {
			border-right: 0;
		}
		a {
			padding: 9px 5px 5px;
			@include flex(center, center);
			flex-wrap: wrap;
			.ico {
				min-height: 27px;
				display: flex;
				align-items: center;
				margin-bottom: 5px;
			}
			span {
				@include font-size(10);
				color: #fff;
				width: 100%;
				text-align: center;
			}
		}
	}
}
