@import "../layout/styles";

.mv-index {
	width: 100%;
	height: 800px;
	position: relative;
	overflow: hidden;
	// .webp & {
	// 	background: url("../../img/top/img-mv.jpg.webp") no-repeat center center / cover;
	// }
	// .no-webp & {
	// 	background: url("../../img/top/img-mv.jpg") no-repeat center center / cover;
	// }
	#movie {
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		// width: 177.77777778vh;
		// height: 56.25vw;
		min-height: 100%;
		min-width: 100%;
	}
	@media only screen and (max-width: 834px) {
		height: 340px;
		// .webp & {
		// 	background: url("../../img/top/img-mv-sp.jpg.webp") no-repeat center center / cover;
		// }
		// .no-webp & {
		// 	background: url("../../img/top/img-mv-sp.jpg") no-repeat center center / cover;
		// }
	}
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 1;
		opacity: 0.5;
		mix-blend-mode: multiply;
		.webp & {
			background: url("../../img/top/mv-bg.png.webp") no-repeat center center / cover;
		}
		.no-webp & {
			background: url("../../img/top/mv-bg.png") no-repeat center center / cover;
		}
	}
	&__img {
		position: relative;
		img {
			width: 100%;
			@media only screen and (max-width: 834px) {
				min-height: 313px;
				object-fit: cover;
				font-family: "object-fit: cover";
			}
		}
	}
	&__ttl {
		position: absolute;
		top: 53%;
		transform: translateY(-50%);
		width: 100%;
		left: 0;
		padding: 20px;
		text-align: center;
		z-index: 10;
		color: #fff;
		font-family: $font-Jost;
		line-height: 1.08;
		letter-spacing: 12px;
		@include font-size(124);
		@include mq(xl) {
			top: 50%;
			@include font-size(80);
		}
		@include mq(lg) {
			@include font-size(60);
		}
		@media only screen and (max-width: 834px) {
			top: 50%;
			letter-spacing: 5.5px;
			@include font-size(36);
		}
		span {
			margin-top: 30px;
			letter-spacing: 2px;
			display: block;
			font-weight: 900;
			margin-bottom: 24px;
			font-family: $font-NotoJP;
			@include font-size(36);
			@include mq(xl) {
				margin-top: 20px;
				margin-bottom: 20px;
				@include font-size(30);
			}
			@include mq(lg) {
				@include font-size(24);
			}
			@media only screen and (max-width: 834px) {
				margin-top: 19px;
				margin-bottom: 21px;
				letter-spacing: 1px;
				@include font-size(18);
			}
		}
		small {
			font-family: $font-NotoJP;
			font-weight: 500;
			display: block;
			letter-spacing: -0.1em;
			@include font-size(20);
			@include mq(xl) {
				@include font-size(18);
			}
			@include mq(lg) {
				@include font-size(16);
			}
			@media only screen and (max-width: 834px) {
				letter-spacing: -1px;
				@include font-size(14);
				line-height: 1.4em;
			}
		}
	}
}

.sec-topics {
	padding: 14px 0;
	background: #e8e8e8;
	@media only screen and (max-width: 834px) {
		padding: 10px 0;
	}
	.inner {
		@include flex(0, center);
		max-width: 1271px;
	}
}

.topics {
	&-heading {
		color: #000;
		margin-right: 32px;
		letter-spacing: 3px;
		font-family: $font-Jost;
		font-weight: 500;
		@include font-size(23);
		@include mq(xl) {
			letter-spacing: 1px;
			@include font-size(20);
		}
		@media only screen and (max-width: 834px) {
			padding-left: 5px;
			letter-spacing: 1.5px;
			margin: 2px 18px 0 0;
			@include font-size(13);
		}
		@include mq(xs) {
			padding-left: 0;
			letter-spacing: 0;
			margin-right: 10px;
		}
	}
	&-post {
		a {
			@include flex();
			span {
				color: #000;
				display: inline-block;
				margin-right: 30px;
				@include font-size(14);
				@media only screen and (max-width: 834px) {
					margin-right: 13px;
					@include font-size(10);
				}
				@include mq(xs) {
					margin-right: 5px;
				}
			}
			p {
				color: #000;
				@include font-size(14);
			}
		}
	}
}

.sec-option {
	padding: 55px 0 68px;
	@media only screen and (max-width: 834px) {
		padding: 24px 0 28px;
	}
	.slick-prev,
	.slick-next {
		width: 30px;
		height: 55px;
		position: absolute;
		z-index: 10;
		cursor: pointer;
		transition: all 0.3s;
		top: 50%;
		transform: translateY(-50%);
		&:before {
			display: none;
		}
		&:hover {
			transition: all 0.3s;
			opacity: 0.7;
		}
		@include mq(lp) {
			width: 20px;
			height: 40px;
		}
		@media only screen and (max-width: 834px) {
			width: 15px !important;
			height: 30px;
		}
	}
	.slick-prev {
		left: -81px;
		background: url("../../img/top/icon-prev.svg") center no-repeat;
		background-size: contain;
		@include mq(lp) {
			left: -22px;
		}
	}
	.slick-next {
		right: -81px;
		background: url("../../img/top/icon-next.svg") center no-repeat;
		background-size: contain;
		@include mq(lp) {
			right: -22px;
		}
	}
}

.option {
	&-list {
		margin: 0 auto;
		max-width: 1000px;
		@include flex();
		@media only screen and (max-width: 834px) {
			margin: 0 10px;
			padding: 0;
		}
		.item {
			padding: 0 10px;
			position: relative;
			@media only screen and (max-width: 834px) {
				padding: 0;
			}
			a {
				display: block;
				color: white;
			}
		}
		&__img {
			img {
				width: 100%;
				@include mq(xl) {
					min-height: 210px;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
				@media only screen and (max-width: 834px) {
					min-height: 119px;
				}
			}
		}
		&__content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding: 17px 31px;
			@media only screen and (max-width: 834px) {
				padding: 9px 11px;
				top: 50%;
				transform: translateY(-50%);
			}
			&-ttl {
				line-height: 1.1667;
				color: #fff;
				margin-bottom: 11px;
				font-weight: bold;
				font-family: $font-gothic;
				@include font-size(42);
				@include mq(xl) {
					margin-bottom: 8px;
					@include font-size(30);
				}
				@media only screen and (max-width: 834px) {
					margin-bottom: 6px;
					@include font-size(21);
				}
				@include mq(xs) {
					@include font-size(18);
				}
				span {
					margin-bottom: 6px;
					letter-spacing: -1px;
					display: block;
					@include font-size(29);
					@include mq(xl) {
						letter-spacing: 0;
						margin-bottom: 2px;
						@include font-size(22);
					}
					@media only screen and (max-width: 834px) {
						margin-bottom: 3px;
						@include font-size(14.5);
					}
				}
			}
			&-logo {
				@include flex(0, center);
				padding-left: 2px;
				img {
					max-width: 178px;
				}
			}
		}
		&__rays {
			width: 139px;
			height: 139px;
			display: block;
			position: absolute;
			right: 21px;
			bottom: 14px;
			background: rgba(#7b7c95, 0.8);
			border-radius: 50%;
			padding: 20px;
			font-weight: bold;
			color: #fff;
			text-align: center;
			line-height: 1.222;
			@include flex(center, center);
			@include font-size(21.6);
			@include mq(xl) {
				width: 110px;
				height: 110px;
				@include font-size(16);
			}
			@media only screen and (max-width: 834px) {
				width: 80px;
				height: 80px;
				padding: 5px;
				right: 5px;
				bottom: 5px;
				@include font-size(12);
			}
		}
	}
}

.sec-about {
	padding: 88px 0 108px;
	background: #000;
	@include mq(xl) {
		padding: 80px 0;
	}
	@media only screen and (max-width: 834px) {
		padding: 33px 0 46px;
	}
	.c-txt__basic02 {
		text-align: center;
		margin-bottom: 79px;
		color: #fff;
		@include mq(xl) {
			margin-bottom: 50px;
		}
		@media only screen and (max-width: 834px) {
			margin-bottom: 32px;
		}
	}
	.c-btn__01 {
		max-width: 235px;
		@media only screen and (max-width: 834px) {
			max-width: 185px;
		}
	}
}

.about {
	&-list {
		@include flex(0, 0, wrap);
		margin: 0 -22px 21px;
		@include mq(xl) {
			margin: 0 -10px 20px;
		}
		@media only screen and (max-width: 834px) {
			margin: 0 -15px 10px;
		}
		.item {
			padding: 0 22px;
			width: 33.33%;
			margin-bottom: 40px;
			@include mq(xl) {
				padding: 0 10px;
			}
			@media only screen and (max-width: 834px) {
				padding: 0 7px;
				width: 100%;
				margin-bottom: 34px;
			}
			.c-txt__basic {
				color: #fff;
				line-height: 1.8;
				text-align: center;
			}

			&:nth-child(4),
			&:nth-child(5) {
				width: calc(50% + 20px);
				padding: 0 40px;
				@include mq(xl) {
					padding: 0 10px;
					width: 50%;
				}
				.about-list__img {
					&:before {
						padding-bottom: 59.3%;
					}
				}
				.c-txt__basic {
					margin-bottom: 4px;
				}
			}
			&:nth-child(4) {
				margin-left: -20px;
				@include mq(xl) {
					margin-left: 0;
				}
			}
			&:nth-child(5) {
				margin-right: -20px;
				@include mq(xl) {
					margin-right: 0;
				}
			}
			// @media only screen and (max-width: 834px) {
			// 	&:nth-child(1) {
			// 		width: 100%;
			// 		.about-list__img {
			// 			&:before {
			// 				padding-bottom: 54.086022%;
			// 			}
			// 		}
			// 		.about-list__number {
			// 			margin-top: -14px;
			// 		}
			// 	}
			// }
		}
		&__img {
			position: relative;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(514 / 744 * 100%);
			}
			&-thumb {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
		}
		&__number {
			display: block;
			color: #fff;
			font-weight: 500;
			line-height: 1;
			font-family: $font-Jost;
			margin-bottom: 30px;
			text-align: center;
			margin-top: -20px;
			position: relative;
			z-index: 2;
			@include font-size(48);
			@include mq(xl) {
				margin-top: -15px;
				margin-bottom: 20px;
				@include font-size(36);
			}
			@media only screen and (max-width: 834px) {
				margin-top: -19px;
				margin-bottom: 9px;
			}
		}
		&__ttl {
			color: #fff;
			font-weight: bold;
			text-align: center;
			margin-bottom: 18px;
			line-height: 1.2;
			@include flex(center, center);
			@include font-size(28);
			@include mq(xl) {
				margin-bottom: 13px;
				@include font-size(24);
			}
			@media only screen and (max-width: 834px) {
				@include font-size(19);
			}
			@include mq(xs) {
				@include font-size(16);
			}
		}
		&__link {
			text-align: center;
			span {
				padding: 10px 19px 10px 0;
				display: inline-block;
				color: #fff;
				font-weight: 500;
				position: relative;
				@include font-size(16);
				@media only screen and (max-width: 834px) {
					@include font-size(14);
					padding-left: 2px;
				}
				&:before {
					content: "";
					width: 127px;
					height: 9px;
					background: url("../../img/common/icon-link.svg") left center no-repeat;
					background-size: contain;
					bottom: 0;
					left: 0;
					position: absolute;
					@media only screen and (max-width: 834px) {
						width: 94px;
						height: 7px;
						bottom: 3px;
					}
				}
			}
		}
	}
}

.sec-pickup {
	padding: 108px 0;
	@media only screen and (max-width: 834px) {
		padding: 48px 0;
	}
	.pickup-block {
		a {
			@include flex(0, center);
			@media only screen and (max-width: 834px) {
				@include flex(0, 0, wrap);
			}
		}
		&__img {
			width: 47.6%;
			position: relative;
			overflow: hidden;
			@media only screen and (max-width: 834px) {
				width: 100%;
			}
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(751 / 1148 * 100%);
			}
			&-thumb {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
		}
		&__ct {
			width: 52.4%;
			padding-left: 132px;
			padding-right: 80px;
			padding-bottom: 10px;
			@include mq(xl) {
				padding: 20px;
			}
			@media only screen and (max-width: 834px) {
				width: 100%;
				padding: 13px 10px;
			}
			&-ttl {
				color: #000;
				line-height: 1.2;
				font-weight: 500;
				margin-bottom: 36px;
				@include mq(xl) {
					margin-bottom: 25px;
				}
				@media only screen and (max-width: 834px) {
					margin-bottom: 17px;
					position: relative;
					&:after {
						content: "";
						width: 10px;
						height: 10px;
						border-top: 2px solid #000;
						border-right: 2px solid #000;
						position: absolute;
						right: 4px;
						top: 9px;
						transform: rotate(45deg);
					}
				}
				.en {
					font-family: $font-Jost;
					display: block;
					letter-spacing: 4.5px;
					margin-bottom: 9px;
					@include font-size(44);
					line-height: 1em;
					@include mq(xl) {
						letter-spacing: 2px;
						margin-bottom: 5px;
					}
					@media only screen and (max-width: 834px) {
						margin-bottom: 2px;
						@include font-size(27);
						line-height: 1.2em;
					}
				}
				.jp {
					display: block;
					@include font-size(20);
					@include mq(xl) {
						@include font-size(18);
					}
					@media only screen and (max-width: 834px) {
						@include font-size(14);
					}
				}
			}
			.c-txt__basic {
				color: #000;
				margin-bottom: 30px;
				@include mq(xl) {
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 834px) {
					margin-bottom: 0;
					font-feature-settings: "palt" 1;
					@include font-size(14);
				}
			}
			.c-btn__02 {
				span {
					color: #000;
					&:before {
						background: url(../../img/common/icon-link02-black.svg) left center no-repeat;
					}
				}
			}
		}
	}
}

.sec-product {
	padding: 108px 0 0;
	background: #000;
	@include mq(xl) {
		padding: 80px 0 0;
	}
	.c-ttl__01 * {
		color: #fff;
	}
	@media only screen and (max-width: 834px) {
		padding: 48px 0 0;
		.c-ttl__01 {
			margin-bottom: 25px;
			.en {
				margin-bottom: 0;
			}
		}
	}
}

.product {
	&-list {
		@include flex();
		@media only screen and (max-width: 834px) {
			@include flex(0, 0, wrap);
		}
		.item {
			width: 33.33%;
			position: relative;
			@media only screen and (max-width: 834px) {
				width: 100%;
			}
			a {
				display: block;
			}
		}
		&__img {
			position: relative;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(788 / 640 * 100%);
				@media only screen and (max-width: 834px) {
					padding-bottom: 44%;
				}
			}
			&-thumb {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
			&:after {
				content: "";
				width: 100%;
				height: 100%;
				background: rgba(21, 21, 21, 0.6);
				top: 0;
				left: 0;
				position: absolute;
			}
		}
		&__ct {
			position: absolute;
			top: 48%;
			left: 0;
			padding: 20px;
			width: 100%;
			text-align: center;
			transform: translateY(-50%);
			@include mq(xl) {
				top: 50%;
			}
			@media only screen and (max-width: 834px) {
				padding: 25px 20px;
			}
			&-logo {
				margin-bottom: 40px;
				@include mq(xl) {
					margin-bottom: 20px;
				}
				@media only screen and (max-width: 834px) {
					margin-bottom: 10px;
				}
				@include mq(xs) {
					margin-bottom: 12px;
				}
				small {
					@include flex(center, center);
				}
				span {
					display: block;
					color: #fff;
					margin-top: 13px;
					@include font-size(17);
					@include mq(xl) {
						margin-top: 10px;
					}
					@media only screen and (max-width: 834px) {
						@include font-size(14);
					}
				}
			}
			p {
				line-height: 2;
				color: #fff;
				@include font-size(19);
				@include mq(xl) {
					line-height: 1.8;
					@include font-size(16);
				}
				@include mq(lg) {
					@include font-size(14);
				}
				@media only screen and (max-width: 834px) {
					line-height: 1.9;
					@include font-size(14);
				}
			}
		}
	}
}

.sec-column {
	padding: 74px 0 120px;
	@include mq(xl) {
		padding: 80px 0 100px;
	}
	@media only screen and (max-width: 834px) {
		padding: 37px 0 80px;
		.c-ttl__01 {
			margin-bottom: 32px;
			.en {
				margin-bottom: 0;
			}
		}
	}
}

.column {
	&-list {
		@include flex();
		margin: 0 -19px 96px;
		@include mq(xl) {
			margin: 0 -10px 50px;
		}
		@media only screen and (max-width: 834px) {
			@include flex(0, 0, wrap);
			margin: 0 3px 65px;
		}
		.item {
			width: 25%;
			padding: 0 19px;
			@include mq(xl) {
				padding: 0 10px;
			}
			@media only screen and (max-width: 834px) {
				padding: 0;
				width: 100%;
				margin-bottom: 22px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			a {
				display: block;
				@media only screen and (max-width: 834px) {
					@include flex();
				}
			}
		}
		&__img {
			position: relative;
			overflow: hidden;
			margin-bottom: 21px;
			@media only screen and (max-width: 834px) {
				margin-bottom: 0;
				width: 41%;
			}
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(196 / 270 * 100%);
			}
			&-thumb {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: "object-fit: cover";
				}
			}
		}
		&__ct {
			@media only screen and (max-width: 834px) {
				width: 59%;
				padding-left: 16px;
				margin-top: -1px;
			}
			&-date {
				display: block;
				color: #000;
				margin-bottom: 5px;
				@include font-size(18);
				@include mq(xl) {
					@include font-size(16);
				}
				@media only screen and (max-width: 834px) {
					@include font-size(14);
				}
			}
			&-ttl {
				line-height: 1.6;
				color: #000;
				word-break: break-all;
				@include font-size(18);
				@include mq(xl) {
					@include font-size(16);
				}
				@include mq(lg) {
					@include font-size(15);
				}
				@media only screen and (max-width: 834px) {
					@include font-size(14);
				}
			}
		}
	}
}
