.inner {
	display: block;
	max-width: 1230px;
	padding: 0 15px;
	width: 100%;
	margin: 0 auto;
	@media only screen and (max-width: 834px) {
		padding: 0 20px;
	}
}

//form input
.form-control {
	display: block;
	width: 100%;
	height: 45px;
	background: white;
	border: 1px solid #ccc;
	font-weight: 500;
	padding: 5px 10px;
	@include font-size(15);
	&:focus {
		border-color: #66afe9;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	}
}

//HTML CSS radio end check box

//<ul>
//	<li>
//		<div class="pure-input">
//			<input id="radio1" name="radio" type="radio" class="radio" checked="checked">
//			<label for="radio1">Option 1</label>
//		</div>
//	</li>
//
//	<li>
//		<div class="pure-input">
//			<input id="radio2" name="radio" type="radio" class="radio">
//			<label for="radio2">Option 2</label>
//		</div>
//	</li>
//
//	<li>
//		<div class="pure-input">
//			<input id="checkbox1" name="checkbox" type="checkbox" checked="checked">
//			<label for="checkbox1">Choice A</label>
//		</div>
//	</li>
//
//	<li>
//		<div class="pure-input">
//			<input id="checkbox2" name="checkbox" type="checkbox">
//			<label for="checkbox2">Choice B</label>
//		</div>
//	</li>
//</ul>

//radio end check box
.pure-input {
	input {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		&[type="checkbox"] {
			& + label {
				&:after {
					background-color: transparent;
					top: 50%;
					left: 4px;
					width: 8px;
					height: 3px;
					margin-top: -4px;
					border-style: solid;
					border-color: #ffffff;
					border-width: 0 0 3px 3px;
					border-image: none;
					transform: rotate(-45deg) scale(0);
				}
			}
			&:checked {
				& + label {
					&:after {
						content: "";
						transform: rotate(-45deg) scale(1);
						transition: transform 200ms ease-out;
					}
					&:before {
						animation: borderscale 200ms ease-in;
						background: #4f8196;
					}
				}
			}
		}
		&[type="radio"] {
			+ label {
				&:after,
				&:before {
					border-radius: 50%;
				}
			}
			&:checked {
				& + label {
					&:before {
						animation: borderscale 300ms ease-in;
						background-color: white;
					}
					&:after {
						transform: scale(1);
					}
				}
			}
		}
	}
	label {
		position: relative;
		padding: 5px 5px 5px 2em;
		vertical-align: middle;
		user-select: none;
		cursor: pointer;
		&:before {
			box-sizing: content-box;
			content: "";
			color: #4f8196;
			position: absolute;
			top: 50%;
			left: 0;
			width: 14px;
			height: 14px;
			margin-top: -9px;
			border: 2px solid #4f8196;
			text-align: center;
			transition: all 0.4s ease;
		}
		&:after {
			box-sizing: content-box;
			content: "";
			background-color: #4f8196;
			position: absolute;
			top: 50%;
			left: 4px;
			width: 10px;
			height: 10px;
			margin-top: -5px;
			transform: scale(0);
			transform-origin: 50%;
			transition: transform 200ms ease-out;
		}
	}
}
