/* ====================================================
Control
==================================================== */
.c-fc {
	//font-color
	&--white {
		color: white !important;
	}
	&--black {
		color: black !important;
	}
}

.c-ft {
	//font-family
	&--en {
		font-family: $font-Jost;
	}
	&--jp {
		font-family: $font-NotoJP;
	}
}

.c-fw {
	//font-weight
	&--lt {
		font-weight: 300 !important;
	}
	&--rg {
		font-weight: 400 !important;
	}
	&--md {
		font-weight: 500 !important;
	}
	&--sbd {
		font-weight: 600 !important;
	}
	&--bd {
		font-weight: 700 !important;
	}
	&--bk {
		font-weight: 900 !important;
	}
}

.c-tx--v {
	//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	&-pc {
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}

.c-al {
	&--c {
		text-align: center !important;
	}

	&--r {
		text-align: right !important;
	}

	&--l {
		text-align: left;
	}
}

.c-val {
	//vertical-align
	&--md {
		vertical-align: middle;
	}
	&--top {
		vertical-align: top;
	}
}

// Scroll Box
.c-scroll-box {
	padding-bottom: 20px;
	overflow-x: scroll;
	overflow-y: hidden;
	&-y {
		padding-bottom: 20px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	&-pc {
		@media only screen and (max-width: 834px) {
			padding-bottom: 20px;
			overflow-x: scroll;
			overflow-y: hidden;
		}
	}
}

// Image position control
.c-pull {
	&--left {
		margin-left: calc(((100vw - 100%) / 2) * -1);
	}
	&--right {
		margin-right: calc(((100vw - 100%) / 2) * -1);
	}
}

/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt {
	&__basic {
		@include fts(16, 32);
		@media only screen and (max-width: 834px) {
			@include fts(14, 24);
		}
		@include mq(xs) {
			letter-spacing: -0.5px;
			@include font-size(11);
		}
		&02 {
			font-weight: 500;
			@include fts(24, 42);
			@media only screen and (max-width: 834px) {
				@include fts(16, 30);
			}
		}
		&03 {
			font-weight: 500;
			@include fts(20, 35);
			@media only screen and (max-width: 834px) {
				font-weight: 400;
				@include fts(14, 24);
			}
		}
	}
}

// Common Title style
%__c-ttl__01 {
}

%__c-ttl__02 {
}

%__c-btn__01 {
}

%__c-btn__02 {
}

.c {
	//ttl
	&-ttl {
		&__01 {
			line-height: 1.2;
			margin-bottom: 80px;
			font-weight: 500;
			text-align: center;
			@include mq(xl) {
				margin-bottom: 50px;
			}
			@media only screen and (max-width: 834px) {
				margin-bottom: 30px;
			}
			.en {
				display: block;
				color: #000;
				font-family: $font-Jost;
				letter-spacing: 4px;
				margin-bottom: 11px;
				@include font-size(48);
				text-transform: uppercase;
				@include mq(xl) {
					margin-bottom: 5px;
					@include font-size(40);
				}
				@media only screen and (max-width: 834px) {
					margin-bottom: 8px;
					letter-spacing: 3px;
					@include font-size(30);
				}
			}
			.jp {
				display: block;
				@include font-size(19);
				@include mq(xl) {
					@include font-size(16);
				}
				@media only screen and (max-width: 834px) {
					@include font-size(14);
				}
			}
			&--white {
				color: #fff;
				.en {
					color: #fff;
				}
			}
		}
		&__02 {
			position: relative;
			background: #000;
			color: white;
			font-family: $font-Jost;
			font-weight: 500;
			@include font-size(29);
			padding: 5px 65px 5px 19px;
			margin-bottom: 16px;
			letter-spacing: 3px;
			@media only screen and (max-width: 834px) {
				@include font-size(14.5);
				padding: 2px 35px 3px 8px;
				margin-bottom: 9px;
				letter-spacing: 1.5px;
			}
			&:after {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-bottom: 52px solid #fff;
				border-left: 65px solid transparent;
				top: 0;
				right: -1px;
				@media only screen and (max-width: 834px) {
					border-bottom: 27px solid #fff;
					border-left: 31px solid transparent;
				}
			}
		}
		&__03 {
			position: relative;
			color: #000;
			font-weight: 500;
			padding: 10px 0 10px 18px;
			margin-bottom: 72px;
			@include font-size(22);
			@media only screen and (max-width: 834px) {
				margin-bottom: 27px;
				padding: 0 0 0 19px;
				@include font-size(16.5);
			}
			&:before {
				content: "";
				width: 7px;
				height: 40px;
				background: #000;
				top: 7px;
				left: 0;
				position: absolute;
				@media only screen and (max-width: 834px) {
					width: 6px;
					height: 30px;
					top: -2px;
					left: 3px;
				}
			}
		}
		&__04 {
			@include fts(30, 58);
			font-weight: bold;
			padding-bottom: 10px;
			margin-bottom: 38px;
			@media only screen and (max-width: 834px) {
				@include fts(16.5, 32);
				padding-bottom: 3px;
				margin-bottom: 20px;
			}
			&--white {
				color: #fff;
				border-bottom: 2px #fff solid;
				@media only screen and (max-width: 834px) {
					border-bottom: 1px #fff solid;
				}
			}
		}
		&__05 {
			position: relative;
			color: #000;
			border-bottom: 2px solid #000;
			font-weight: bold;
			@include font-size(44);
			padding: 0 0 14px 97px;
			margin-bottom: 31px;
			letter-spacing: 2.5px;
			@include mq(xl) {
				@include font-size(30);
				padding-left: 65px;
				letter-spacing: 0.5px;
			}
			@media only screen and (max-width: 834px) {
				border-bottom: 1px solid #000;
				@include font-size(22);
				padding: 0 0 5px 44px;
				margin-bottom: 12px;
				line-height: 1.6;
			}
			span {
				position: absolute;
				font-family: $font-Jost;
				font-weight: 500;
				@include font-size(53);
				letter-spacing: 0;
				top: -5px;
				left: 5px;
				@include mq(xl) {
					@include font-size(38);
					left: 0;
				}
				@media only screen and (max-width: 834px) {
					@include font-size(26.5);
					top: -3px;
				}
			}
		}
	}

	//btn
	&-btn {
		&__01 {
			max-width: 170px;
			height: 55px;
			width: 100%;
			margin: 0 auto;
			color: #fff;
			border: 1px solid #fff;
			padding: 5px 11px 8px 5px;
			text-align: center;
			font-weight: 500;
			@include flex(center, center);
			@include font-size(19);
			@media only screen and (max-width: 834px) {
				max-width: 126px;
				height: 39px;
				@include font-size(14);
			}
			&--black {
				color: #000;
				border-color: #000;
			}
			&--view {
				padding: 10px;
				font-family: $font-Jost;
				height: 48px;
				max-width: 161px;
				@include font-size(14);
				@media only screen and (max-width: 834px) {
					height: 35px;
					max-width: 119px;
					letter-spacing: 1px;
				}
			}
		}
		&__02 {
			span {
				padding: 10px 10px 10px 0;
				display: block;
				position: relative;
				font-weight: 500;
				color: #fff;
				@include font-size(16);
				&:before {
					content: "";
					width: 308px;
					height: 9px;
					background: url("../../img/common/icon-link02.svg") left center no-repeat;
					background-size: contain;
					bottom: 0;
					left: 0;
					position: absolute;
					@include mq(xl) {
						width: 200px;
						height: 8px;
					}
				}
			}
		}
	}
}

//fade up
.scrollin {
	opacity: 0;
	-webkit-transform: translate(0, 70px);
	-ms-transform: translate(0, 70px);
	transform: translate(0, 70px);
	&.active {
		opacity: 1;
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		-o-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
}

.mv-page {
	position: relative;

	&__img {
		img {
			width: 100%;
		}
	}

	.inner {
		position: absolute;
		width: 100%;
		left: 50%;
		top: 46%;
		transform: translate(-50%, -50%);
		@media only screen and (max-width: 834px) {
			top: 48%;
		}
	}

	&__ttl {
		.c-ft {
			&--en {
				display: block;
				@include font-size(108);
				line-height: 1.16;
				letter-spacing: 1.4px;
				@include mq(xl) {
					@include font-size(70);
				}
				@media only screen and (max-width: 834px) {
					@include font-size(36);
					line-height: 1.3;
					letter-spacing: 0.3px;
				}
			}

			&--jp {
				display: block;
				font-weight: 900;
				@include font-size(32);
				letter-spacing: 1px;
				@media only screen and (max-width: 834px) {
					@include font-size(15);
					letter-spacing: 0.3px;
				}
			}
		}
	}
}
